<template>
  <div class="BottomCenter">
    <div class="limitBox">
      <div v-html="state.html" class="wxParse"></div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";
import { watchEffect } from 'vue';
export default {
  name: "BottomCenter",
  components: {},
  props: [],
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      html: "",
      key: router.currentRoute._rawValue.query.key,
    });
    const GetDataList = () => {
      let keys = "about_us";
      if (state.key == 1) keys = "about_copyright";
      if (state.key == 2) keys = "about_link";
      if (state.key == 3) keys = "about_site";
      if (state.key == 4) keys = "home_banner1_c";
      if (state.key == 5) keys = "home_banner2_c";
      api.GetConfigs(keys).success((res) => {
        state.html = res.data.data[keys];
      });
    }
    // 监听
    watchEffect(() => {
      if (router.currentRoute.value.query.key) {
        state.key = Number(router.currentRoute.value.query.key);
        GetDataList();
      }
    });
    return {
      state,
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
.BottomCenter {
  background: #fff;
  padding: 40px 0;
  min-height: calc(100vh - 500px);
}
</style>